//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import add from './add.vue'
import deptSelector from '../dept/component/dept-selector.vue'
import peopleDept from './people-dept.vue'
import caBind from './component/ca-bind'


export default {
    props:[],
    components:{add,deptSelector,peopleDept,caBind},
    data: function(){
        return{
            //搜索条件
            pgData: {
                title: '',
                cataname: '',

                sortname: '',
                sortorder: ''
            },
            form: {
                tableData: [],
            },

            dialogPeopleVisible: false,

            dialogAddVisible: false,
            editUserid: '',
            formTitle: '',

            //搜索栏
            formInline:{
                truename: '',
                deptcode: ''
            },
            editAllController: 0,//全部编辑控制器
        }
    }, 
    computed:{
        pgQuery: function(){
                return {
                pagesize: 10,
                sortname: this.pgData.sortname ? this.pgData.sortname : 'addtime',
                sortorder: this.pgData.sortorder ? this.pgData.sortorder : 'desc',

                title: this.formInline.truename,
                deptcode: this.formInline.deptcode
            }
        },
        listpath:{
            get:function(){
                return (this.reqtype&&this.reqtype=="register")?"/user/templist":"/user/list";
            }
        },
        deptpath:{
            get:function(){
                return (this.reqtype&&this.reqtype=="register")?"../user/people-dept.html?type=register":"../user/people-dept.html?";
            }
        },
        showRePsg: function() {
            return !/register/.test(window.location.href);
        }
    },
    watch:{
        dialogAddVisible: function(n, o){
            if(!n){
                this.$refs.page.reload();
            }
        },
        dialogPeopleVisible: function(n, o){
            if(!n){
                this.$refs.page.reload();
            }
        },
    },

    methods: {
        sortChange: function(sort){
            this.pgData.sortname = sort.prop
            this.pgData.sortorder = sortorder(sort.order)
            this.$refs.page.queryData()
        },
        doSearch: function(){
            this.$refs.page.queryData();
        },
        toEditRow: function(row){
            this.editUserid = row.userid;
            this.formTitle = '编辑人员';
            this.dialogAddVisible = true;
        },
        addPeople:function(){
            this.editUserid = '';
            this.formTitle = '添加人员';
            this.dialogAddVisible = true;
        },
        handleDel: function (row) {
            var that = this;

            if(!!row.userid){
                var url = (this.reqtype && this.reqtype == 'register') ? '/user/tempdel' : '/user/del'

                this.$post(this.$store.getters.sysUrl + url, {rowguid: row.userid}, function(data){
                    ShowMsg.call(this, '删除成功', 'success');
                    that.$refs.page.queryData();
                })
            }else{
                this.form.tableData.splice(this.form.tableData.indexOf(row), 1);
            }
        },

        selDept: function(){
            this.formUrl_prople = this.deptpath;
            this.dialogPeopleVisible = true;
        },
        resetPsw: function(row) {
            ShowConfirm.call(this, '密码将重置为“abc123”', 'info', function() {
                this.$post(this.$store.getters.sysUrl + '/user/resetpwd', {
                    rowguid: row.userid
                }, function(data, res) {
                    ShowMsg.call(this, res.msg);
                })
            }.bind(this))
        },
        afterQuery: function(row, data) {
            var one = (this.reqtype&&this.reqtype == "register") ? 1 : 0;
            this.total = Number(data.total) + one;
        },
        bindCaHandler(row) {
            this.$refs.caBind.setInfo(row.truename, row.userid);
            this.$refs.caBind.show();
        },
        unBindCaHandler(row) {
            this.$post(this.$store.getters.sysUrl + '/user/caunbind', {
                userid: row.userid
            }, (data, res) => {
                ShowMsg.call(this, res.msg);
                this.doSearch();
            })
        }

    },
    mounted() {

    }
}
