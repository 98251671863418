//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import deptSelector from '../dept/component/dept-selector.vue'
import deptSelector2 from '../dept/component/new-dept-selector.vue'

export default {
    components: {deptSelector,deptSelector2},
    props: {
        userid: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            form:{
				pwd: 'abc123',
				userid: '',
                loginname: ''
			},
			pswShow: true,
            cityOptions:[
                // {
                //     label:"全部",
                //     value:"0",
                // },
                {
                    label:"石家庄市",
                    value:"130100",
                },
                {
                    label:"唐山市",
                    value:"130200",
                },
                {
                    label:"秦皇岛市",
                    value:"130300",
                },
                {
                    label:"邯郸市",
                    value:"130400",
                },
                {
                    label:"邢台市",
                    value:"130500",
                },
                {
                    label:"保定市",
                    value:"130600",
                },
                {
                    label:"张家口市",
                    value:"130700",
                },
                {
                    label:"承德市",
                    value:"130800",
                },
                {
                    label:"沧州市",
                    value:"130900",
                },
                {
                    label:"廊坊市",
                    value:"131000",
                },
                {
                    label:"衡水市",
                    value:"131100",
                },
            ],
            areaidList:[],
            areanameList:[],
        }
    },
    computed: {

    },
	methods:{
		queryData: function(){
			var that = this;
			that.$get(that.$store.getters.sysUrl + '/user/detail', {rowguid: that.form.userid}, function(data){
				that.form = data;
				if(!!data.userid){
					that.pswShow = false;
				}
                if(that.form.areaid == '0'){
                    that.selectAll();
                }else{
                    that.areaidList = that.form.areaid.split(',');
                }
			})
		},
		submit: function(){
			var that = this;
            if(that.areaidList.includes('0')){
                that.form.areaid = '0';
                that.form.areaname = '全部';
            }else{
                that.areanameList = [];
                let arr = [];
                for(var i = 0;i < that.areaidList.length;i++){
                    let cityitem = that.cityOptions.filter(function(item){
                        return that.areaidList[i] == item.value;
                    })
                    arr = [...arr,...cityitem];
                }
                arr.forEach(function(item){
                    that.areanameList.push(item.label);
                })
                that.form.areaid =  that.areaidList.toString();
                that.form.areaname = that.areanameList.toString();
            }

			that.$post(that.$store.getters.sysUrl + '/user/add', that.form, function(data, res){
				ShowMsg( res.msg, 'success');
				that.cancle();
			})
		},
		cancle: function(){
            enpty_obj(this.form, ['pwd'])
			this.$emit('close');
		},
		deptChangeHandler: function(e){
			this.form.deptcode = e.deptcode;
			this.form.deptname = e.deptname;
		},

        reload() {
            this.form.userid = this.userid;
            !!this.form.userid && this.queryData();
        },
        areaChange:function(val){
            var that = this;
            if (!val.includes('0') && val.length === that.cityOptions.length) {
                that.areaidList.unshift('0')
            } else if (val.includes('0') && (val.length - 1) < that.cityOptions.length) {
                that.areaidList = that.areaidList.filter((item) => {
                    return item !== '0'
                })
            }
        },
        selectAll() {
            if (this.areaidList.length < this.cityOptions.length) {
                this.areaidList = []
                this.cityOptions.map((item) => {
                this.areaidList.push(item.value)
            })
                this.areaidList.unshift('0');
                this.form.areaid = '0';
                this.form.areaname = '全部';
            } else {
                this.areaidList = [];
            }
            
        },
        removeTag(val) {
            if (val === '0') {
                this.areaidList = []
            }
        },
	},
	mounted:function(){
		this.reload();
	}
}
