//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    components: {},
    props: {},
    data(){
        return{
            form:{
                newPassword:'',
                oldPassword:'',
            }
        }
    },
    computed: {

    },
    watch:{
    
    },
    methods: {
        submit:function(){
            this.$post('/bhtong/sysmg/user/changepwd',{
                newpwd:this.form.newPassword,
                oldpwd:this.form.oldPassword
            },function(data){
                this.form.newPassword = '';
                this.form.oldPassword = '';
                ShowMsg('密码修改成功','success');
            })
        },
        cancle:function(){
            this.$router.go(-1)
        },
  

    },
    mounted:function(){
        
    }
}
