//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data: function(){
        return {
            options: [],
            listArr: []
        }
    },
    computed: {
        model: {
            get: function(){
                var value = this.value;

                var cascaderArr=[];
                var node = {};

                this.listArr.forEach(function(item){
                    if(item.deptcode === value){
                        node = item;
                    }
                })

                if(JSON.stringify(node) != '{}') {
                    do{
                        cascaderArr.unshift(node.deptcode);

                        for(var i = 0; i < this.listArr.length; i++){
                            if(this.listArr[i].deptcode === node.parentcode){
                                node = this.listArr[i];
                                break;
                            }
                        }

                    }while (node.parentcode != '0' && !!node.parentcode);
                }

                this.listArr.forEach(function(item){
                    if(item.parentcode == '0' && cascaderArr.length != 0){
                        cascaderArr.unshift(item.deptcode);
                    }
                })

                return cascaderArr;
            },
            set: function(e){
                this.$emit('input', e[e.length-1] || '');

                var obj = null;
                this.listArr.forEach(function(item){
                    if(item.deptcode == e[e.length-1]){
                        obj = item;
                    }
                })
                this.$emit('change', obj || {});
            }
        }
    },
    mounted: function(){
        var that = this;
        this.$get(this.$store.getters.sysUrl + '/dwdept/list', {}, function(data){
            that.listArr = data;
            that.options = arrBuildTree(data, 'parentcode', 'deptcode');
        })
    }
}
