//
//
//
//
//
//
//
//
//
//
//

import deptSelector from '../dept/component/dept-selector'
import selectPeople from './component/select-people'

export default {
    props: [],
    components: {deptSelector, selectPeople},
    data () {
        return {
            form: {
				people: [],
				dept: '',
				deptrowguid: ''
			}
        }
    },
    methods: {
        deptChange: function(e){
			this.form.deptrowguid = e.rowguid;
		},
		dialogSubmit: function(){
            var that = this;
			this.$post(this.$store.getters.sysUrl+'/dwdept/allotuser', {
				rowguid: that.form.deptrowguid,
				userids: that.form.people.join(',')
			}, function(data, res){
				ShowMsg.call(this, res.msg);
				this.dialogCancle();
			})
        },
        dialogCancle: function(){
            this.$emit('close');
        },
		deptSeled: function(e){
			var that = this;
			this.$get(this.$store.getters.sysUrl + '/dwdept/userlist', {
				deptcode: e
			}, function(data){
				data = data || [];
				that.form.people = data.map(function(item){
					return item.userid;
				});
			})
		},
        reload() {
            this.form.people = [];
        }
    },
    mounted: function() {

    }
}
